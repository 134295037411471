.App {
  text-align: center;
}

.App-logo {
  max-height: 10vh;
  max-width: 90vw;
  width: auto;
  height: auto;
  pointer-events: none;
}

.App-header {
  background-color: #eeeeee;
  color: rgb(50, 50, 50);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #0b6403;
}
